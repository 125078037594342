@font-face {
  font-family: "Whyte";
  src: url("Whyte-Italic.woff2") format("woff2"),
    url("Whyte-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Whyte";
  src: url("Whyte-ExtraLightItalic.woff2") format("woff2"),
    url("Whyte-ExtraLightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Whyte Book";
  src: url("Whyte-Book.woff2") format("woff2"),
    url("Whyte-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Whyte";
  src: url("Whyte-HeavyItalic.woff2") format("woff2"),
    url("Whyte-HeavyItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Whyte";
  src: url("Whyte-Regular.woff2") format("woff2"),
    url("Whyte-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Whyte Super";
  src: url("Whyte-Super.woff2") format("woff2"),
    url("Whyte-Super.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Whyte";
  src: url("Whyte-LightItalic.woff2") format("woff2"),
    url("Whyte-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Whyte Book";
  src: url("Whyte-BookItalic.woff2") format("woff2"),
    url("Whyte-BookItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Whyte";
  src: url("Whyte-Heavy.woff2") format("woff2"),
    url("Whyte-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Whyte Super";
  src: url("Whyte-SuperItalic.woff2") format("woff2"),
    url("Whyte-SuperItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Whyte";
  src: url("Whyte-MediumItalic.woff2") format("woff2"),
    url("Whyte-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Whyte";
  src: url("Whyte-Bold.woff2") format("woff2"),
    url("Whyte-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Whyte";
  src: url("Whyte-Black.woff2") format("woff2"),
    url("Whyte-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Whyte";
  src: url("Whyte-BlackItalic.woff2") format("woff2"),
    url("Whyte-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Whyte";
  src: url("Whyte-ExtraLight.woff2") format("woff2"),
    url("Whyte-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Whyte";
  src: url("Whyte-Light.woff2") format("woff2"),
    url("Whyte-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Whyte";
  src: url("Whyte-Medium.woff2") format("woff2"),
    url("Whyte-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Whyte";
  src: url("Whyte-BoldItalic.woff2") format("woff2"),
    url("Whyte-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Whyte";
  src: url("Whyte-ThinItalic.woff2") format("woff2"),
    url("Whyte-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Whyte";
  src: url("Whyte-Thin.woff2") format("woff2"),
    url("Whyte-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}
