@tailwind base;
@tailwind components;
@tailwind utilities;

@media only screen and (min-width: 640px) {
  .bg-banner {
    background-size: cover;
    background-image: url("../images/banner-min-1.webp");
  }
}
@media only screen and (max-width: 640px) {
  .bg-main {
    background-image: url("../images/header-rectangle.png");
  }
}

.bg-header {
  background-image: url("../images/header-rectangle.png");
  background-size: cover;
}
/* .bg-banner {
  background-image: url("../images/banner-min-1.webp");
} */
.bg-footer-index::after {
  content: "";
  background-image: url("../images/footer-1.webp");
  width: 100%;
  height: 100%;
  background-size: cover;
  position: absolute;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  z-index: -1;
}
.bg-footer {
  background-image: url("../images/footer-rectangle.png");
  background-size: cover;
}
